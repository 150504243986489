import React from "react";
import { Helmet } from "react-helmet";
import { graphql, PageProps } from "gatsby";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";

import { AiFillHeart } from "@react-icons/all-files/ai/AiFillHeart";

import Layout from "../components/layout";

type DataProps = {
  allInstaNode: any;
};

export default function SocialMedia({ data }: PageProps<DataProps>) {
  return (
    <Layout>
      <Helmet>
        <title>Polle Kinderboek - Social Media</title>
      </Helmet>
      <p className="text-5xl md:text-7xl 2xl:text-9xl text-center align-middle px-5 pt-10 font-polle">
        Social Media
      </p>
      <div className="grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-5 px-10">
        {data.allInstaNode.edges.map(function (object: any, i: number) {
          // Get the image data.
          const image: any = getImage(object.node.localFile);

          // Shorten te caption.
          let caption: string;
          if (object.node.caption <= 100) {
            caption = object.node.caption;
          } else {
            caption = object.node.caption.substr(0, 100 - 1);
            caption = caption.substr(0, caption.lastIndexOf(" "));
          }

          // Background colour based on number in array.
          let even: boolean;
          if (i % 2 == 0) {
            even = true;
          } else {
            even = false;
          }

          let background: string;
          if (even) {
            background = "bg-blue-polle fill-yellow-polle";
          } else {
            background = "bg-yellow-polle fill-blue-polle";
          }

          return (
            <a
              href={`https://www.instagram.com/p/${object.node.id}/`}
              className={`flex flex-col text-black m-5 ${background} rounded-lg overflow-hidden transition-transform duration-500 hover:scale-105 shadow-md`}
            >
              <GatsbyImage image={image} alt={object.node.caption} />
              <p className="p-5 text-sm font-polle">{caption}&hellip;</p>
              <div className="ml-5 mb-5 mt-auto text-xs">
                <div className="flex flex-row items-center">
                  <AiFillHeart className={`text-4xl ${background}`} />
                  <p className="ml-2 font-bold">{object.node.likes} likes</p>
                </div>
              </div>
            </a>
          );
        })}
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    allInstaNode(sort: { fields: timestamp, order: DESC }) {
      edges {
        node {
          likes
          comments
          timestamp
          caption
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: AUTO
                aspectRatio: 1
                layout: FULL_WIDTH
              )
            }
          }
          id
        }
      }
    }
  }
`;
